@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  & input[type=password]::-webkit-contacts-auto-fill-button,
  & input[type=password]::-webkit-credentials-auto-fill-button {
    top: 30px !important;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.passwordWrapper {
  position: relative;
}

.passwordShow {
  position: absolute;
  cursor: pointer;
  top: 32px;
  right: 15px;
  z-index: 10;
  text-decoration: underline;
  color: var(--marketplaceColorLight);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;

  @media (--viewportMedium) {
    top: 45px;
  }
}

.welcomeBack {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}